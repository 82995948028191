import React, { useRef, useEffect } from 'react'
import style from './index.module.less'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useIntl } from "react-intl"
import {
    Adjustable_Clear,
    Adjustable_Shade,
    Adjustable_Theater,
    // TUV_ColorAccuracy,
    // TUV_Eyecomfort,
    // TUV_LowBlueLight,
    // TUV_HighVisibility,
    GF_FOV_video,
    one_tuv,
    adjustable_bg,
    GF_FOV_Mobile
}
    from '@/images/one/index'
import useNavigate from '@/hooks/useNavigate'
import getCountry from '@/utils/getCountry.js'
import useBigScreen from "@/hooks/bigScreen"

const Upto146 = () => {
    gsap.registerPlugin(ScrollTrigger)
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    const imgBoxRef = useRef()
    const boxRef = useRef()
    const blurBoxRef = useRef()
    const blurUpRef = useRef()
    const blurDownRef = useRef()
    const blurTextRef = useRef()
    const screenTextRef0 = useRef()
    const screenTextRef1 = useRef()
    const screenTextRef2 = useRef()
    const region = getCountry()

    const text_arr = [
        intl.formatMessage({ id: "one_part9_text5" }),
        intl.formatMessage({ id: "one_part9_text6" }),
        intl.formatMessage({ id: "one_part9_text7" })
    ]
    const adjustable_arr = [
        {
            img: "",
            icon: Adjustable_Clear,
            type: intl.formatMessage({ id: "one_part10_text3" })
        },
        {
            img: "",
            icon: Adjustable_Shade,
            type: intl.formatMessage({ id: "one_part10_text4" })
        },
        {
            img: "",
            icon: Adjustable_Theater,
            type: intl.formatMessage({ id: "one_part10_text5" })
        }
    ]

    const ipd_arr = [
        {
            title: "120 Hz",
            desc: intl.formatMessage({ id: "one_part11_text1" }),
            tip: intl.formatMessage({ id: "one_part11_text2" })
        }, {
            title: "ΔE <3",
            desc: intl.formatMessage({ id: "one_part11_text3" }),
            tip: intl.formatMessage({ id: "one_part11_text4" })
        }, {
            title: intl.formatMessage({ id: "one_part11_text5" }),
            desc: '',
            tip: intl.formatMessage({ id: "one_part11_text6" })
        }
    ]

    // const tuv_arr = [
    //     TUV_Eyecomfort, TUV_HighVisibility, TUV_ColorAccuracy, TUV_LowBlueLight
    // ]
    useEffect(() => {
      const ifBigScreen = window.innerWidth > 768;
      console.log('ifPc',ifPc, ifBigScreen)
        if (ifBigScreen) {
            const tl = gsap.timeline({
              scrollTrigger: {
                trigger: boxRef.current,
                start: "top top",
                end: "bottom top",
                scrub: 0.5,
                pin: true,
                // markers: true,         
              }
            })

            tl.to(screenTextRef0.current, {
              duration: 0.5,
              opacity: 1,
              ease: "power1.out",
            })
            .to(imgBoxRef.current, {
              duration: 1,
              clipPath: 'inset(100px 200px round 20px)',
              ease: "power1.out",
            })
            .to(screenTextRef0.current, {
              duration: 0.5,
              opacity: 0,
              ease: "power1.out",
            }, '<')
            .to(screenTextRef1.current, {
              duration: 1,
              opacity: 1,
              ease: "power1.out",
            }, '<')
            .to(imgBoxRef.current, {
              duration: 1,
              clipPath: 'inset(0px 0px round 20px)',
              ease: "power1.out",
            })
            .to(screenTextRef1.current, {
              duration: 0.5,
              opacity: 0,
              ease: "power1.out",
            }, '<')
            .to(screenTextRef2.current, {
              duration: 1,
              opacity: 1,
              ease: "power1.out",
            }, '<')

        }else{
            gsap.killTweensOf(boxRef.current);
            gsap.killTweensOf(imgBoxRef.current);
        }


        //blur
        gsap.to(blurUpRef.current, {
            opacity: 0,
            y: '-50%',
            scrollTrigger: {
                trigger: blurBoxRef.current,
                start: "30% center" ,
                end:'center center',
                scrub:true

            },
        });
        gsap.to(blurDownRef.current, {
            y: '-50%',
            filter: 'blur(0)',
            scrollTrigger: {
                trigger: blurBoxRef.current,
                start: "30% center" ,
                end:'center center',
                scrub:true
            },
        });
        gsap.to(blurTextRef.current, {
            opacity: 0,
            scrollTrigger: {
                trigger: blurBoxRef.current,
                start: "30% center" ,
                end:'center center',
                scrub:true
            },
        });

    }, [])

    return (
        <>
            {ifPc? <div className={style.box} ref={boxRef}>
                <div className={style.title}>{intl.formatMessage({ id: "one_part9_text1" })}</div>
                <div className={style.desc} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "one_part9_text2" })}` }}>

                </div>
                <div className={style.content}>
                    <div className={style.contentLeft}>
                        <div className={style.contentLeftItem}>
                            <div className={style.contentLeftItemTitle}>50°</div>
                            <div className={style.contentLeftItemDesc}>{intl.formatMessage({ id: "one_part9_text3" })}</div>
                        </div>
                        <div className={style.contentLeftItem}>
                            <div className={style.contentLeftItemTitle}>{region == 'jp' || region == 'kr' ? '367\"' : "147\""}</div>
                            <div className={style.contentLeftItemDesc}>{intl.formatMessage({ id: "one_part9_text4" })}</div>
                        </div>
                    </div>
                    <div className={style.contentRight}>
                        <div className={style.contentRightBox} ref={imgBoxRef}>
                            <video autoPlay playsInline loop muted width="100%" >
                                <source src={GF_FOV_video} type="video/mp4" />
                            </video>
                        </div>
                        <div className={style.contentRightText} ref={screenTextRef0} dangerouslySetInnerHTML={{ __html: `${text_arr[0]}` }}></div>
                        <div className={style.contentRightText} ref={screenTextRef1} dangerouslySetInnerHTML={{ __html: `${text_arr[1]}` }}></div>
                        <div className={style.contentRightText} ref={screenTextRef2} dangerouslySetInnerHTML={{ __html: `${text_arr[2]}` }}></div>

                    </div>
                </div>
            </div>: <div className={style.mbox} >
                <div className={style.title}>{intl.formatMessage({ id: "one_part9_text1" })}</div>
                <div className={style.desc} dangerouslySetInnerHTML={{ __html: `${intl.formatMessage({ id: "one_part9_text2" })}` }}>

                </div>
                <div className={style.content}>
                    <div className={style.contentLeft}>
                        <div className={style.contentLeftItem}>
                            <div className={style.contentLeftItemTitle}>50°</div>
                            <div className={style.contentLeftItemDesc}>{intl.formatMessage({ id: "one_part9_text3" })}</div>
                        </div>
                        <div className={style.contentLeftItem}>
                            <div className={style.contentLeftItemTitle}>{region == 'jp' || region == 'kr' ? '367\"' : "147\""}</div>
                            <div className={style.contentLeftItemDesc}>{intl.formatMessage({ id: "one_part9_text4" })}</div>
                        </div>
                    </div>
                    <div className={style.contentRight}>
                        <div className={style.contentRightBox} >
                            <img src={GF_FOV_Mobile} alt='one fov' className={style.contentRightBoxImg}/>
                        </div>
                        <div className={style.contentRightText} dangerouslySetInnerHTML={{ __html: `${text_arr[2]}` }}>

                        </div>
                    </div>
                </div>
            </div>}
           
            <div className={style.adjustable} >
                <div className={style.title}>  {intl.formatMessage({ id: "one_part10_text1" })}</div>
                <div className={style.desc}>{intl.formatMessage({ id: "one_part10_text2" })}</div>
                <div className={style.adjustableBox} style={{ backgroundImage: `url(${adjustable_bg})` }}>
                    {adjustable_arr.map((item, index) => (
                        <div className={style.adjustableBoxItem} key={`one_upto146_adjustableItem${index}`}>
                            <img src={item.icon} />
                            <div className={style.adjustableBoxItemTitle}>
                                {item.type}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={style.adjustable}>
                <div className={style.title}>  {intl.formatMessage({ id: "one_tuv_title" })}</div>
                <div className={style.adjustableBox2} style={{ backgroundImage: `url(${one_tuv})` }}>
                    {/* {tuv_arr.map((item, index) => (
                        <div className={style.cuv} key={`one_cuv_${index}`}>
                            <img src={item} />
                        </div>
                    ))} */}
                </div>
            </div>
            <div className={style.ipd}>
                {ipd_arr.map((item, index) => (
                    <div className={style.ipdItem} key={`ipdItem${index}`}>
                        <div className={style.ipdItemTitle}>
                            {item.title}
                        </div>
                        {!!item.desc && <div className={style.ipdItemDesc}>
                            {item.desc}
                        </div>}
                        <div className={style.ipdItemTip}>{item.tip}</div>

                    </div>
                ))}
                <div className={style.know} onClick={() => useNavigate('/ipd')}>{intl.formatMessage({ id: "one_part11_text7" })}</div>
            </div>
            <div className={style.blur} ref={blurBoxRef}>
                <div className={style.blurBox}>
                    <div className={style.blurBoxText} ref={blurTextRef}>
                        {ifPc?intl.formatMessage({ id: "one_part12_text1" }):<> {intl.formatMessage({ id: "one_part12_text2_1" })}<br/> {intl.formatMessage({ id: "one_part12_text2_2" })}</>}
                        {/* {intl.formatMessage({ id: "one_part12_text1" })} */}
                    </div>
                    <div className={style.blurBoxUp} ref={blurUpRef}>    {ifPc?intl.formatMessage({ id: "one_part12_text1" }):<> {intl.formatMessage({ id: "one_part12_text2_1" })}<br/> {intl.formatMessage({ id: "one_part12_text2_2" })}</>}</div>
                    <div className={style.blurBoxDown} ref={blurDownRef}>   {ifPc?intl.formatMessage({ id: "one_part12_text1" }):<> {intl.formatMessage({ id: "one_part12_text2_1" })}<br/> {intl.formatMessage({ id: "one_part12_text2_2" })}</>}</div>
                </div>
            </div>

        </>

    )
}
export default Upto146